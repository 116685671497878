<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.batchElevatorParts')"
    id="addBatchElevatorParts"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.selectElevatorsYouNeed") }}</span>
      </template>
      <div class="text item">
        <jl-table
          :tableData="eleData"
          @init="getElevator"
          :options="options"
          :columns="eleColumns"
          :operates="operates"
          :total="eleTotal"
          :height="$defined.HEIGHT - 360 + 'px'"
          @handleSelectionChange="
            (val) => {
              handleSelectionChange(val, 'selectElevator');
            }
          "
        ></jl-table>
      </div>
    </el-card>
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.selectPart") }}</span>
      </template>
      <div class="text item">
        <jl-table
          :tableData="partData"
          @init="getPart"
          :options="options"
          :columns="partColumns"
          :operates="operates"
          :total="partTotal"
          :height="$defined.HEIGHT - 360 + 'px'"
          @handleSelectionChange="
            (val) => {
              handleSelectionChange(val, 'selectPart');
            }
          "
        ></jl-table>
      </div>
    </el-card>
    <div class="button-groups">
      <el-button type="primary" @click="onSubmit" round>
        {{ $t("i18n.confirm") }}
      </el-button>
      <el-button class="el-button--reset" @click="$router.go(-1)" round>
        {{ $t("i18n.cancel") }}
      </el-button>
    </div>
    <el-dialog
      :title="$t('i18n.new')"
      v-model="dialogNewVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-collapse v-model="activeNames">
        <el-collapse-item
          v-for="(item, index) in submitData"
          :key="index"
          :name="index"
        >
          <template #title>
            <span class="width">
              {{ $t("i18n.elevatorNo") }}：{{ item.number }}
            </span>
            <el-button type="text" @click.stop="addPartFunc(index)">
              {{ $t("i18n.addPart") }}
            </el-button>
          </template>
          <div v-for="(child, cIndex) in item.partDtos" :key="child.id">
            <span class="width">{{ cIndex + 1 }}、{{ child.name }}</span>
            <el-button type="text" @click="delPart(index, cIndex)">
              {{ $t("i18n.delete") }}
            </el-button>
          </div>
        </el-collapse-item>
      </el-collapse>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogNewVisible = false">
            {{ $t("i18n.cancel") }}
          </el-button>
          <el-button type="primary" @click="mulAddParts">
            {{ $t("i18n.confirm") }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.new')"
      v-model="newPartVisible"
      :close-on-click-modal="false"
      width="80%"
      destroy-on-close
    >
      <jl-table
        @init="getAddPartList"
        :tableData="addPartData"
        :options="addPartOptions"
        :columns="addPartColumns"
        :operates="addPartOperates"
        :total="addPartTotal"
        @handleSelectionChange="
          (val) => {
            handleSelectionChange(val, 'selectNewData');
          }
        "
        height="500"
      ></jl-table>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import jlTable from "../../components/table";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default {
  name: "AddBatchElevatorParts",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      activeNames: [0],
      operates: {
        // 列操作按钮
        width: 0,
        fixed: "right",
        list: [],
      },
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      eleColumns: [
        // 需要展示的列
        {
          prop: "number",
          label: "elevatorNo",
          width: 200,
        },
        {
          prop: "contract_number",
          label: "contractNo",
        },
        {
          prop: "contractNumber",
          label: "contractNo",
          search: true,
          rows: false,
          type: "input",
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
          width: 210,
          search: true,
          type: "input",
        },
        {
          prop: "elevator_type",
          label: "elevatorType",
          align: "center",
          width: 80,
        },
      ],
      eleData: [],
      eleTotal: 0,
      partColumns: [
        // 需要展示的列
        {
          prop: "name",
          label: "partName",
          align: "center",
          type: "input",
          search: true,
        },
        {
          prop: "replacementCycle",
          label: "replacementCycle",
          align: "center",
          row: false,
        },
      ],
      partData: [],
      partTotal: 0,
      selectElevator: [],
      selectPart: [],
      dialogNewVisible: false,
      submitData: [],
      newPartVisible: false,
      addPartData: [],
      addPartTotal: 0,
      addPartColumns: [
        {
          prop: "name",
          label: "partName",
          align: "center",
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "replacementCycle",
          label: "replacementCycle",
          align: "center",
          row: false,
        },
        {
          prop: "remark",
          label: "remarks",
          align: "left",
          type: "textarea",
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            if (row.remark.indexOf("\n") >= 0) {
              return row.remark.replace(/\n/g, "<br/>");
            } else {
              return row.remark;
            }
          },
        },
      ],
      addPartOperates: {
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "addPart",
            icon: "el-icon-plus",
            class: "add",
            show: true,
            type: "outer",
            method: () => {
              addNewPart();
            },
          },
        ],
      },
      addPartOptions: {
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      selectIndex: "",
    });

    const getElevator = async (params) => {
      const { data } = await proxy.$api.system.elevatorByContract(params);
      state.eleData = data.records;
      state.eleTotal = data.total;
    };

    const getPart = async (params) => {
      const { data } = await proxy.$api.detection.partManagePage(params);
      state.partData = data.records;
      state.partTotal = data.total;
    };

    const handleSelectionChange = (val, key) => {
      state[key] = val;
    };

    const getAddPartList = async (params) => {
      let id = state.submitData[state.selectIndex].id;
      const { data } = await proxy.$api.detection.getNotPartByElevator(
        id,
        params
      );
      state.addPartData = data.records;
      state.addPartTotal = data.total;
    };

    const addNewPart = async () => {
      // 新增部件到电梯
      var data = checkSelectPart("selectNewData");
      if (data.length > 0) {
        data.map((item) => {
          let current = state.submitData[state.selectIndex].partDtos.filter(
            (obj) => obj.id == item.id
          );
          if (current.length === 0) {
            state.submitData[state.selectIndex].partDtos.push(item);
          }
        });
        proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
        state.newPartVisible = false;
      }
    };

    const checkSelectPart = (key) => {
      if (state[key].length > 0) {
        return state[key];
      } else {
        proxy.$defined.tip(t("i18n.selectPart"));
        return "";
      }
    };

    const addPartFunc = (pIndex) => {
      state.selectIndex = pIndex;
      state.newPartVisible = true;
    };

    const onSubmit = () => {
      if (state.selectElevator.length > 0 && state.selectPart.length > 0) {
        state.submitData = JSON.parse(JSON.stringify(state.selectElevator));
        state.submitData.map((item) => {
          item.partDtos = JSON.parse(JSON.stringify(state.selectPart));
        });
        state.dialogNewVisible = true;
      } else if (state.selectElevator.length == 0) {
        proxy.$defined.tip(t("i18n.selectElevatorsYouNeed"));
      } else {
        proxy.$defined.tip(t("i18n.selectPart"));
      }
    };

    const delPart = (pIndex, cIndex) => {
      state.submitData[pIndex].partDtos.splice(cIndex, 1);
    };

    const mulAddParts = async () => {
      var arr = [];
      state.submitData.map((item) => {
        var obj = { elevatorId: item.id, partDtos: [] };
        item.partDtos.map((child) => {
          obj.partDtos.push({ mountingsId: child.id });
        });
        arr.push(obj);
      });
      await proxy.$api.detection.mulAddParts(arr);
      proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
      router.push({ path: "/detection/batchElevatorParts" });
    };

    return {
      ...toRefs(state),
      getElevator,
      getPart,
      handleSelectionChange,
      onSubmit,
      delPart,
      getAddPartList,
      addPartFunc,
      mulAddParts,
    };
  },
};
</script>

<style lang="scss">
#addBatchElevatorParts {
  .el-card {
    display: inline-block;
    vertical-align: top;
    width: 44%;
    margin-left: 2%;
    &:first-child {
      width: 54%;
      margin: 0;
    }
  }
  .button-groups {
    text-align: center;
    margin-top: 40px;
  }
  .el-collapse {
    height: 500px;
    overflow-y: auto;
    .width {
      min-width: 240px;
      display: inline-block;
    }
  }
}
@media screen and (max-width: 768px) {
  #addBatchElevatorParts {
    margin-top: -20px;
    .el-card {
      width: 100% !important;
      margin: 0 !important;
    }
  }
}
</style>
